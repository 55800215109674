import { NextPage } from "next";
import type { AppProps } from "next/app";
import { ElementType, useEffect } from "react";
import {
  AnimatePresence,
  domAnimation,
  LazyMotion,
  motion,
} from "framer-motion";
import {
  CacheProvider,
  EmotionCache,
  ThemeProvider as EmotionProvider,
} from "@emotion/react";
import { inspect } from "@xstate/inspect";
import { ThemeProvider } from "@mui/system";
import { AppProvider } from "src/contexts/AppContext";
import theme from "src/theme";
import Global from "src/components/Global";
import LayoutDashboard from "src/components/LayoutDashboard";
import createEmotionCache from "src/utils/createEmotionCache";
import UnknownState from "src/components/UnknownState";
import { NavigationProvider } from "src/contexts/NavigationContext";
import LayoutBackground from "src/components/LayoutBackground";
import { WebSocketSubmissionProvider } from "src/contexts/WebSocketSubmissionContext";
import * as ga from "../lib/analytics";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { LicenseInfo } from "@mui/x-license-pro";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { WebSocketSessionProvider } from "src/contexts/WebSocketSessionContext";
import { StatusReportProvider } from "src/contexts/StatusReportContext";
import { WebSocketManageSessionProvider } from "src/contexts/WebSocketManageSessionContext";
import ApplicationInsightsProvider from "../src/contexts/ApplicationInsightsContext";
import { WebSocketCdStatusReportTapesProvider } from "src/contexts/WebSocketCdStatusReportTapesContext";
import ErrorBoundary from "src/structures/ErrorBoundary";
import i18nAltai from "src/i18n/i18nAltai";
import { I18nextProvider } from "react-i18next";
import { Suspense } from "react";

if (typeof window !== "undefined" && process.env.NODE_ENV === "development") {
  inspect({ iframe: false });
}

const clientSideEmotionCache = createEmotionCache();

export type NextPageWithLayout = NextPage & {
  Layout?: ElementType;
  Provider?: ElementType;
  SecondaryProvider?: ElementType;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  emotionCache: EmotionCache;
};

// Tranistion
const variants = {
  hidden: { opacity: 0, x: 0 },
  enter: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 0 },
};

interface DefaultProviderProps {
  children: React.ReactNode;
}

// Temporary wrapper to allow for custom provider
const DefaultProvider = ({ children }: DefaultProviderProps) => <>{children}</>;

function AltaiCasingApp(props: AppPropsWithLayout) {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps,
    router,
  } = props;

  // Utilise pre-defined layout or use Dashboard
  const Layout = Component.Layout ?? LayoutDashboard;
  // Apply additional provider if defined
  const Provider = Component.Provider ?? DefaultProvider;
  // Apply secondary provider if defined (used to persist context between to pages where there's already a context uitlised)
  const SecondaryProvider = Component.SecondaryProvider ?? DefaultProvider;

  // Set MUI-X license key globally
  LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_X_LICENSE!);

  useEffect(() => {
    const handleRouteChange = (url: any) => {
      ga.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <CacheProvider value={emotionCache}>
      <AppProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ApplicationInsightsProvider>
            <ThemeProvider theme={theme}>
              <I18nextProvider i18n={i18nAltai}>
                {/* Note issues with mui styled, utilising emotion directly and leveraging theme values */}
                <EmotionProvider theme={theme}>
                  <Suspense fallback="...loading">
                    <Global />
                    <UnknownState>
                      <WebSocketSessionProvider>
                        <WebSocketSubmissionProvider>
                          <WebSocketManageSessionProvider>
                            <WebSocketCdStatusReportTapesProvider>
                              <SecondaryProvider>
                                <NavigationProvider>
                                  <Provider>
                                    <LayoutBackground />
                                    <Layout>
                                      <LazyMotion features={domAnimation}>
                                        <AnimatePresence
                                          exitBeforeEnter
                                          initial={false}
                                          onExitComplete={() =>
                                            window.scrollTo(0, 0)
                                          }
                                        >
                                          <motion.div
                                            initial="hidden"
                                            animate="enter"
                                            exit="exit"
                                            variants={variants}
                                            key={router.route}
                                            transition={{
                                              type: "spring",
                                              duration: 0.3,
                                            }}
                                          >
                                            <ErrorBoundary>
                                              <Component {...pageProps} />
                                            </ErrorBoundary>
                                          </motion.div>
                                        </AnimatePresence>
                                      </LazyMotion>
                                    </Layout>
                                  </Provider>
                                </NavigationProvider>
                              </SecondaryProvider>
                            </WebSocketCdStatusReportTapesProvider>
                          </WebSocketManageSessionProvider>
                        </WebSocketSubmissionProvider>
                      </WebSocketSessionProvider>
                    </UnknownState>
                  </Suspense>
                </EmotionProvider>
              </I18nextProvider>
            </ThemeProvider>
          </ApplicationInsightsProvider>
        </LocalizationProvider>
      </AppProvider>
    </CacheProvider>
  );
}

export default AltaiCasingApp;
