import { useEffect } from "react";
import type { ReactNode } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Typography } from "@mui/material";
import { useActor } from "@xstate/react";
import HeaderBar from "src/components/HeaderBar";
import LayoutDashboardBase from "src/components/LayoutDashboardBase";
import { Breadcrumbs, BreadCrumbLink } from "src/components/Breadcrumbs";
import Navigation from "src/components/Navigation";
import { useNavigation } from "src/contexts/NavigationContext";
import HeaderBarMenu from "../HeaderBarMenu";
import { NAVIGATION_TAGS } from "src/machines/navigationMachine";
import { useApp } from "src/contexts/AppContext";
import NotificationStream from "../NotificationStream";
import Head from "next/head";
import { useTranslation } from "react-i18next";
import { COMMON } from "src/i18n/constants/namespaces";

interface DashboardPageProps {
  children: ReactNode;
}

const DashboardPage = ({ children }: DashboardPageProps) => {
  const { t: getTranslation } = useTranslation([COMMON]);
  const router = useRouter();
  const { appService } = useApp();
  const [appState] = useActor(appService);
  const navigationService = useNavigation();
  const [state, send] = useActor(navigationService);

  const {
    active,
    breadcrumb: { list },
    secondary: { ready },
    headerMenu,
  } = state.context;

  useEffect(() => {
    const path = router.asPath;
    send({ type: "UPDATE", path });
    send({ type: "PONG_ACTIVE_POSITION", path });
  }, [router]);

  return (
    <LayoutDashboardBase
      active={active}
      onClose={() => send({ type: "INACTIVE" })}
      header={
        <HeaderBar
          onOpen={() => send({ type: "ACTIVE" })}
          breadcrumbActive={state.hasTag(NAVIGATION_TAGS.PRIMARY) || ready}
          actions={
            <>
              {appState.context.auth.userType === "CastingDirector" && (
                <NotificationStream />
              )}
              <HeaderBarMenu
                options={headerMenu}
                onChange={(id) => send({ type: "HEADER_MENU_UPDATE", id })}
              />
            </>
          }
          breadcrumb={
            <Breadcrumbs aria-label="breadcrumb">
              {list.map((v, index) => {
                const isLast = index === list.length - 1;
                if (isLast) {
                  return (
                    <Typography key={v.id} color="inherit">
                      {v.labelKey
                        ? getTranslation(`navigation.${v.labelKey}`, {
                            pathName: v.pathLabel,
                            ns: COMMON,
                            defaultValue: v.pathLabel,
                          })
                        : ""}
                    </Typography>
                  );
                }
                return (
                  <Link key={v.id} href={v.href} passHref legacyBehavior>
                    <BreadCrumbLink underline="hover" color="inherit">
                      {v.labelKey
                        ? getTranslation(`navigation.${v.labelKey}`, {
                            pathName: v.pathLabel,
                            ns: COMMON,
                            defaultValue: v.pathLabel,
                          })
                        : ""}
                    </BreadCrumbLink>
                  </Link>
                );
              })}
            </Breadcrumbs>
          }
        />
      }
      navigation={<Navigation actor={navigationService} />}
    >
      <Head>
        {/* Meta tag containing user id for IE integration */}
        <meta
          name="altai-user-id"
          content={appState.context.auth?.firebaseId ?? ""}
        />
      </Head>
      {children}
    </LayoutDashboardBase>
  );
};

export default DashboardPage;
